import React from 'react';
import Fade from 'react-reveal/Fade';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Image from 'reusecore/src/elements/Image';
import Container from 'common/src/components/UI/Container';
import SectionWrapper, { ContentWrapper } from './privacy.style';
import Illustration from 'common/src/assets/image/cryptoModern/illustration1.png';

const PrivacyPortal = () => {
  return (
    <SectionWrapper>
      <Container>
        <ContentWrapper>
          <div className="content">
            <Heading content="Privacy Policy" />
            {/* <Paragraph> */}
              <p>Neua Software built the Wombat Hero app as a Free app. This SERVICE is provided by Neua Software at no cost and is intended for use as is.</p>
              <p>This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.</p>
              <h3>Information Collection and Use</h3>
              <p>For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to N/A. The information that we request will be retained by us and used as described in this privacy policy.</p>
              <p>The app does NOT use third party services to collect information used to identify you.</p>
              <p>Link to privacy policy of third party service providers used by the app:</p>
              <a href='https://ads.google.com/intl/en_AU/home/'>Google Adsense</a>

              <h3>Cookies</h3>
              <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.</p>
              <p>This Service does not use these “cookies” explicitly. However, the app may use third party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.</p>

              <h3>Security</h3>
              <p>We do not retain or use any personal information, this app is external from accounts.</p>
              <p>The game saves to your local storage on your mobile device.</p>

              <h3>Links to Other Sites</h3>
              <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>

              <h3>Changes to This Privacy Policy</h3>
              <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>

              <h3>Contact Us</h3>
              <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at team@neua.com.au.</p>
            
            {/* </Paragraph> */}
          </div>
          {/* <div className="image">
            <Fade up>
              <Image src={Illustration} alt="Illustration Image" />
            </Fade>
          </div> */}
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default PrivacyPortal;
