import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { theme } from 'common/src/theme/appModern';
import { ResetCSS } from 'common/src/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from '../containers/WombatHero/Navbar';
import Banner from '../containers/WombatHero/Banner';
import Privacy from '../containers/WombatHero/Privacy';
import Footer from '../containers/WombatHero/Footer';
import GlobalStyle, {
    AppWrapper,
    ContentWrapper,
} from '../containers/WombatHero/appModern.style';
import '@redq/reuse-modal/es/index.css';

import SEO from '../components/seo';

export default function () {
    return (
        <ThemeProvider theme={theme}>
            <>
                <SEO title="Wombat Hero" />
                <Modal />
                <ResetCSS />
                <GlobalStyle />

                <AppWrapper>
                    <Sticky top={0} innerZ={9999} activeClass="sticky-active">
                        <Navbar />
                    </Sticky>
                    <ContentWrapper>
                        <Banner />
                        <Privacy />
                    </ContentWrapper>
                    {/* <Footer /> */}
                </AppWrapper>
            </>
        </ThemeProvider>
    );
}
